import React from "react";
import { graphql } from "gatsby";
import dayjs from "dayjs";

import { Layout } from "~components";
import { Link, safePrefix } from "~utils";

const page = {
  frontmatter: {
    title: "Slides",
    subtitle: "All my slides/presentations",
    keywords: [],
    template: "listing",
    mock: true,
  },
  fields: {
    url: "/slides/",
  },
};

function DeckPreview({ excerpt, date, title, slug }) {
  return (
    <article className="post">
      <div className="post-inside">
        <header className="post-header">
          <h2 className="post-title">
            <Link to={safePrefix(slug)} rel="bookmark">
              {title}
            </Link>
          </h2>
        </header>
        <div className="post-content">
          <Link to={safePrefix(slug)} rel="bookmark">
            <p>{excerpt}</p>
          </Link>
        </div>
        <footer className="post-meta">
          <time
            className="published"
            dateTime={date ? dayjs(date).format("YYYY-MM-DD HH:mm") : "---"}
          >
            {date ? dayjs(date).format("MMMM DD, YYYY") : "---"}
          </time>
        </footer>
      </div>
    </article>
  );
}

/**
 *
 * @param {{
 *  data: GatsbyTypes.SlidesQuery
 * }} props
 */
export default function Decks(props) {
  const { data } = props;
  const { slides, ...pageData } = data;

  return (
    <Layout
      pageData={{
        ...pageData,
        page,
      }}
    >
      <div className="post-feed">
        {slides.edges.map(({ node }, index) => {
          const { fields, frontmatter } = node;
          return (
            <DeckPreview
              key={`${fields.slug}-${index}`}
              {...frontmatter}
              slug={`slides${fields.slug}`}
            />
          );
        })}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Slides($headerImg: String) {
    ...HeaderImg
    slides: allMdx(
      filter: {
        frontmatter: { draft: { ne: true } }
        fileAbsolutePath: { glob: "/**/src/slides/*" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            draft
            date
            excerpt
            title
          }
        }
      }
    }
  }
`;
